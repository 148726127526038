import React from 'react'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Backdrop, Tooltip, Fab} from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { useNavigate,useLocation } from "react-router-dom";

export const FabMenu =({userInfo}:any)=>
{
  const navigate = useNavigate();
  const actions = [
    {
      icon: <FileCopyIcon/>,
      name: 'History',
      act:  ()=>{
        navigate('/history')
      }
    },
    {
      icon: <SettingsIcon/>,
      name: 'Settings',
      act:  ()=>{
        navigate('/settings')
      }
    },
    // { icon: <Share />, name: 'Share' },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Backdrop open={open} />
      <SpeedDial
        FabProps={{color:'inherit'}}
        ariaLabel="SpeedDial"
        // color='info'
        sx={{ position: 'fixed', bottom: 16, left: 16, color: 'inherit' }}
        icon={<SpeedDialIcon icon={<AccountCircle/>} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={()=>{action.act()}}
            tooltipPlacement='right'
          />
        ))}
      </SpeedDial>
    </React.Fragment>
  )

}


export const FabMenuUnAuth =()=>
{
  const navigate = useNavigate();
  const location = useLocation()
  if( !location ){
    return null
  }

  return (
    <Fab  variant={"extended"} color="inherit"
          sx={{ position: 'fixed', bottom: 16, left: 16, }}
          onClick={()=>{ navigate('/login', {state:{ from: location}}) }}>
      <AccountCircle/>Register / Login
    </Fab>
  )
}

export const FabMenuUnAuth2 =()=>
{
  const navigate = useNavigate();
  const actions = [
    {
      icon: <FileCopyIcon/>,
      name: 'Login',
      act:  ()=>{
        navigate('/settings')
      }
    },
    {
      icon: <FileCopyIcon/>,
      name: 'Register',
      act:  ()=>{
        navigate('/history')
      }
    },
    // { icon: <Share />, name: 'Share' },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <React.Fragment>
      <Backdrop open={open} />

        <SpeedDial
          FabProps={{color:'inherit'}}
          ariaLabel="SpeedDial"
          // color='info'
          sx={{ position: 'fixed', bottom: 16, left: 16, color: 'inherit' }}
          icon={
            <Tooltip open={true} sx={{}} title="Register / Login" placement="right" arrow>
            <AccountCircle >
              Register / Login
            </AccountCircle>
            </Tooltip>
          }

          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction

              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={()=>{action.act()}}
              tooltipPlacement='right'
            />
          ))}
        </SpeedDial>

    </React.Fragment>
  )

}
