import { Auth }                   from 'aws-amplify';
import {
  CommentOutlined,
  CommentsDisabledOutlined, }     from '@mui/icons-material';
import {
  Stack,Typography,
  ListItem, List, ListItemButton, ListItemAvatar, ListItemText,
  Avatar,CircularProgress,}       from '@mui/material'

import { useEffect, useState }    from 'react';
import {  useNavigate }           from 'react-router-dom';
import PageVisibility             from 'react-page-visibility';

import dataProv                   from '../dataProvider'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu}                  from '../utils/fabMenu'
import {FabNavigateBack}          from '../utils/route'

type SlotViewModel = {
  host: {
    avatar_src: string,
    group_name: string,
  },

  line: {
    name:     string,
    desc:     string,
    url:      string,
    is_open:  string,
  },
  id:         string,
  value_txt:  string,
  update_time: Date,
}

export const CocoHistory = () =>{

  const [slotModels, setSlotModels]     = useState<SlotViewModel[]>([])
  const navigate = useNavigate();
  // const [userInfo,     setUserInfo]     = useState<any|undefined>()
  const [refreshCount, setRefreshCount] = useState(0)

  const [isLoading, setIsLoading] = useState(true)

  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo, ]                  = useState<SnackbarInfo>({
    message: "default msg", severity: 'success'
  })

  useEffect(()=>{

    new Promise(async()=>{

      setIsLoading(true)
      const uinfo = await Auth.currentUserInfo()
      // console.log({userInfo: uinfo})

      const slotResult = await dataProv.getList(
        'slots', {
          pagination: {page:1, perPage:10},
          sort:       {field: 'create_time', order:'DESC'},
          filter:     {user_id: uinfo.attributes.sub}
        }
      );

      const slotModelList: SlotViewModel[] = []
      for(const slot of slotResult.data){
        const host = slot.cocoline.host

        slotModelList.push({
          host: {
            avatar_src: host.src,
            group_name: host.groupName,
          },
          line: {
            name:     slot.cocoline.title,
            desc:     slot.cocoline.desc.slice(0,35) + '...',
            url:      `/line/${slot.cocoline.id}`,
            is_open:  slot.cocoline.is_open,
          },
          id:          slot.id,
          value_txt:   slot.comment,
          update_time: slot.update_time,
        })
      }
      setSlotModels(slotModelList)
      setIsLoading(false)
    }).catch((e)=>{
      setIsLoading(false)
    })

  },[refreshCount])

  const refresh = ()=>{
    setRefreshCount(refreshCount+1)
  }

  const handleItemClicked=(slot: SlotViewModel)=>{
    navigate(slot.line.url, {state: {}})
  }

  return (
    <main style={{  }}>
    <Stack justifyContent='center' direction="column" spacing={1} paddingY='15px'>
      <Typography textAlign='center' variant='h1' sx={{ fontSize:'44px'}}>
        History
      </Typography>
      {
        isLoading?
        <Stack direction="row" justifyContent='center'>
          <CircularProgress/>
        </Stack>
        :
        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {slotModels.map((slot) => {
              // const labelId = `checkbox-list-secondary-label-${slot.id}`;
              return (
                <ListItem
                  key={slot.id}
                  // secondaryAction={
                  //   <Checkbox
                  //     edge="end"
                  //     // onChange={handleToggle(value)}
                  //     checked={checked.indexOf(value) !== -1}
                  //     inputProps={{ 'aria-labelledby': labelId }}
                  //   />
                  // }

                  disablePadding
                >
                  <ListItemButton onClick={()=>{handleItemClicked(slot)}}>
                    <ListItemAvatar>
                      <Avatar
                        // alt={`Avatar n°${value + 1}`}
                        alt={slot.host.group_name}
                        src={slot.host.avatar_src}
                        // src={`/static/images/avatar/${value + 1}.jpg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                        id={`${slot.id}-title`}
                        primary={slot.line.name}
                        secondary={slot.line.desc}
                    />
                    {
                      slot.line.is_open?
                        <CommentOutlined color='info'/>:
                        <CommentsDisabledOutlined color='disabled'/>
                    }
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
      }
      </Stack>
      <FabMenu/>
      <FabNavigateBack/>
      <PageVisibility onChange={async(isVisible)=>{
        // console.log({isVisible})
        if(isVisible){
          // When show to front
          // try{
          //   const userInfo = await Auth.currentUserInfo();
          //   console.log({userInfo})
          //   // const user = await Auth.currentAuthenticatedUser();
          // }catch(e){
          //   console.log( typeof(e))
          //   console.log('error: ' + e);
          // }
        }
      }}>
      </PageVisibility>
      <MySnackBar open={showSnackBar} setOpen={setShowSnackBar} info={snackBarInfo}/>
    </main>
  );
}
