import { GetOneResult } from 'react-admin';
import { stringify } from 'query-string';
import { API } from "aws-amplify";

const apiUrl = 'https://cp0qkkqz9d.execute-api.ap-southeast-1.amazonaws.com/prod';
// const apiUrl = 'https://localhost:3002/';
// const httpClient = fetchUtils.fetchJson;

export default {

    getOne: (resource: any, params: any): Promise<GetOneResult> => {

      return API.get('coco-user', `/${resource}/${params.id}`, {}).then(
        response => {
          // console.log(response);
          return Promise.resolve(
            {
              id: response.id,
              data: response
            }
          );
      });
    },

    create: async (resource: string, params: any) =>{

      console.log(params)

      try{
        console.log(params)
        const res_create = await API.post('coco-user', `/${resource}`, {
          body: {
            ...params.data,
          }
        })

        return Promise.resolve({
          data: {...params.data, id: res_create.id},
          total: 0, // response.length
        });
      }
      catch(e:any){
        console.log(e)
        return Promise.reject(e.response.data??e);
      }
    },

    update: async (resource: string, params: any) => {
      try{
        console.log(params)

        await API.put('coco-user', `/${resource}/${params.id}`, {
          body: {
            ...params.data,
          }
        })

        return Promise.resolve({
          data: {...params.data, id: params.id},
        });
      }
      catch(e:any){
        console.log(e)
        return Promise.reject(e.response.data??e);
      }
    },


    getList: (resource:string, params: any) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
      };
      // const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return API.get(
        'coco-user',
        `/${resource}?${stringify(query)}`,
        {}
      ).then(response => {
        // console.log(response);
        return {
          data:   response.data,
          total:  response.total
        };
      })

  },
}
