import { Auth }                       from 'aws-amplify';
import {
  InsertEmoticon,
  LocationCity,
  PhoneIphone
} from '@mui/icons-material';
import {
  Stack, Typography, TextField,  InputAdornment,
  CircularProgress, Button}       from '@mui/material'
import {LoadingButton }           from '@mui/lab'

import React, { useEffect, useState }    from 'react';
// import { useNavigate }            from 'react-router-dom';

import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu}                  from '../utils/fabMenu'
import {FabNavigateBack, useAuth} from '../utils/route'

import {useConfirm }              from 'material-ui-confirm';


type UserInfo = {
  postal_code?:  string,
  nick_name?:    string,
  phone_num?:    string,
  phone_num_verified?: boolean,
}

export const Settings = () =>{

  const [refreshCount, setRefreshCount] = useState(0)
  const [userInfo,    setUserInfo] = useState<UserInfo>();

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving,  setIsSaving]  = useState(false)

  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo,  setSnackBarInfo]  = useState<SnackbarInfo>({
    message: "default msg", severity: 'success'
  })
  // const navigate  = useNavigate();
  const confirm   = useConfirm()
  const {setUser} = useAuth()

  useEffect(()=>{

    new Promise(async()=>{

      console.log('useEffect ..')
      setIsLoading(true)

      const uinfo = await Auth.currentUserInfo()
      console.log({userInfo: uinfo})
      setUserInfo({
        postal_code:  uinfo.attributes['custom:postal_code'],
        nick_name:    uinfo.attributes.nickname,
        phone_num:    uinfo.attributes.phone_number,
        phone_num_verified: uinfo.attributes.phone_number_verified,
      })

      setIsLoading(false)
    }).catch((e)=>{
      setIsLoading(false)
    })

  },[refreshCount])

  const refresh = ()=>{
    setRefreshCount(refreshCount+1)
  }

  const handleSave = ()=>{
    console.log(userInfo)
    const data = {
      'custom:postal_code': userInfo?.postal_code,
      'nickname':           userInfo?.nick_name,
    }
    setIsSaving(true)
    new Promise( async()=>{
      const user = await Auth.currentAuthenticatedUser();
      console.log({data})

      await Auth.updateUserAttributes(user, data);
      setIsSaving(false)
      setShowSnackBar(true)
      setSnackBarInfo({
        message: "Saved", severity: 'success'
      })
    }).catch(()=>{
      setIsSaving(false)
      setShowSnackBar(true)
      setSnackBarInfo({
        message: "Some error occour", severity: 'error'
      })
    })

    setIsSaving(false)
    return {data: data}
  }


  const handleLogout = async()=>{

    await confirm({
      title: 'Confirm',
      description: 'Proceed Logout ? ',
    }).then(async() => {
      console.log('then')
      await Auth.signOut()
      setUser(null)
    }).catch((e) => {
      console.log( e?e:'cancel')
    });
  }
  const handleChange =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      console.dir(event.target)
      console.dir(event.target.value)
      setUserInfo({ ...userInfo, [prop]: event.target.value });
    };

  return (
    <main style={{  }}>
    <Stack justifyContent='center' direction="column" spacing={1} paddingY='15px'>
      <Typography textAlign='center' variant='h1' sx={{ fontSize:'44px'}}>
        Settings
      </Typography>
      {
        isLoading?
        <Stack direction="row" justifyContent='center'>
          <CircularProgress/>
        </Stack>
        :(
        userInfo&&
        <Stack justifyContent='center' direction="column"
                alignItems='center'
                spacing={0} paddingY='15px'>
            <TextField fullWidth
              disabled
              label="Phone number"
              id="phonenum"
              value={userInfo.phone_num}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphone/>
                  </InputAdornment>
                )
              }}
            />
            <TextField onChange={handleChange('nick_name')}
              label="Name / NickName"
              id="nickname"
              value={userInfo.nick_name}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertEmoticon/>
                  </InputAdornment>
                )
              }}
            />
            <TextField onChange={handleChange('postal_code')}
              label="Postal Code"
              id="postcode"
              value={userInfo.postal_code}
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCity/>
                  </InputAdornment>
                )
              }}
            />
            <LoadingButton sx={{ m: 1, width: '30ch' }} onClick={handleSave}
                loading={isSaving} variant='outlined' size='large' color='info'>
              Save
            </LoadingButton>


            <Button sx={{ m: 1, width: '30ch', color:'warning' }}
                onClick={handleLogout}
                variant='outlined' size='large' color='error'>
              Logout
            </Button>
        </Stack>
        )
      }
      </Stack>
      <FabMenu/>
      <FabNavigateBack/>
      <MySnackBar open={showSnackBar} setOpen={setShowSnackBar} info={snackBarInfo}/>
    </main>
  );
}
