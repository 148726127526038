import { useNavigate }          from 'react-router'
import {
    Box, Typography, IconButton,
    Avatar, AppBar, Toolbar,}   from '@mui/material'
import { ElevationScroll,}      from './lineUtils'


import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const LineTopBar=({line}:any)=>{
  const navigate = useNavigate()
  const handleAvatarClicked = ()=>{
    navigate(`/host/${line.user_id}`, {
      state: {
        groupName: line.host.groupName,
        groupDesc: line.host.groupDesc,
        avatarSrc: line.host.src,
        hostId:    line.user_id,
      }
    })
  }
  return(
    <ElevationScroll>
        <AppBar sx={{
          width: '0.95',
          bgcolor: "#EEEEEE",
          marginTop: '10px',
          marginX: '10px',
          marginLeft: '10px',
          borderRadius: '35px',
          border: 1,
          borderColor: '#FFFFFF',
          opacity: 0.92,
        }}
        position="fixed"
        >
          <Toolbar sx={{paddingX: '10px'}}>
              <IconButton onClick={handleAvatarClicked} sx={{padding:'0px'}}>
                <Avatar alt="Host" src={line.host.src??'..'} style={{
                  width: 44, height: 44,
                }} />
              </IconButton>
              <Box sx={{paddingLeft: '12px',}} width='.99' >
                <Typography variant="h1" sx={{fontSize: '18px'}} color='black'  >
                {line.title}
                </Typography>
              </Box>
              {
                line.whatsapp_group &&
                <Box justifyContent="flex-end">
                  <a href={line.whatsapp_group.invitationLink} target="_blank" rel="noreferrer">
                    <Avatar
                     style={{
                      width: 44, height: 44, paddingRight: 0,
                    }}
                    >
                      <WhatsAppIcon />
                    </Avatar>
                  </a>
                </Box>
              }
          </Toolbar>
        </AppBar>
    </ElevationScroll>
  )
}
