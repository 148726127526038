import CheckIcon    from '@mui/icons-material/Check';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Box,Button, Typography,Divider,CircularProgress,
  Dialog, DialogProps, DialogContent, DialogTitle, DialogActions,
  Stack,  TextField,
} from '@mui/material'

import * as React from 'react'
import {useState} from 'react'
import {
  openWhatsappToPaste,
  generateWhatsAppMsg }     from './lineUtils'

export const SendingDialog = (props: any) => {
  const {open, isSending, handleClose, slots, whatsAppLink} = props;

  const [scroll] = React.useState<DialogProps['scroll']>('paper');
  const [copied, setCopied] = useState(false)

  return (
    <Dialog
        fullWidth={true}
        // maxWidth='xs'
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{
          // backgroundColor: '#EEEEEE',
          // opacity: 0.95
        }}
      >
        <DialogTitle id="scroll-dialog-title" textAlign="start">
          <Stack direction='row'>
            {
              isSending? <CircularProgress color="success" size='35px' />
                        :<CheckIcon color='success' fontSize='large' />
            }
            <Box width='100px' paddingX='10px'>
              {isSending? "Sending":"Sent   "}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={ scroll === 'paper'}>
          <Stack direction='column' spacing={2}  >
            <TextField id="outlined-multiline-flexible"
              multiline maxRows={6} disabled fullWidth
              value={generateWhatsAppMsg(slots)}
            />
            <Typography sx={{}} textAlign='center'>
              { isSending? "Sending": "Sent & copied to clipboard 📋✔️ " }
            </Typography>
            {
              // (!isSending)&&
              <Button variant="contained" color='inherit' endIcon={<MobileScreenShareIcon />}
                  onClick={async(e)=>{
                    await openWhatsappToPaste(slots, document.getElementById('copy-btn'), whatsAppLink);
                    setCopied(true)
                  }}
                  disabled={isSending}>
                Open Whatsapp to paste
              </Button>
            }
            </Stack>
        </DialogContent>
        <DialogActions>
          {
            copied && <Button onClick={handleClose}> Register </Button>
          }
          <Button onClick={handleClose}> {copied? "Close": "Close"} </Button>
        </DialogActions>
    </Dialog>
  )
}



export const FabDialog = (props: any) => {
  const {open, slots, handleClose, whatsAppLink, } = props;
  const handleGoWhatsapp = () => {

    window.open(whatsAppLink, '_blank')
  }

  const handleCopyAndGoWhatsapp = async () => {

    await openWhatsappToPaste(slots, document.getElementById('copy-btn'), whatsAppLink);
  }

  return <Dialog
        fullWidth={true}
        // maxWidth='xs'
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{
          // backgroundColor: '#EEEEEE',
          // opacity: 0.95
          borderRadius: '15px'
        }}
      >
        <DialogTitle id="scroll-dialog-title"> Please choose </DialogTitle>
        <DialogContent
          dividers={
            false
          } >
            <Button variant="contained" color='inherit' fullWidth
                    onClick={handleGoWhatsapp}  >
              Open WhatsApp Group &nbsp; <WhatsAppIcon/>
            </Button>
            <br/>&nbsp;<br/>
            <Divider/>
            <Button variant="contained" color='inherit' fullWidth
                    onClick={handleCopyAndGoWhatsapp}  >
              Copy and open WhatsApp &nbsp; <ContentCopyTwoToneIcon/>
            </Button>

            <TextField
              id="outlined-multiline-flexible"
              disabled
              fullWidth
              multiline
              maxRows={6}
              value={generateWhatsAppMsg(slots)}
            />

        </DialogContent>
        {
          // <DialogActions>
          //   <Button onClick={handleGoWhatsapp}> handleGoWhatsapp </Button>
          // </DialogActions>
        }
      </Dialog>

}
