import {Auth} from 'aws-amplify'
import {Navigate,Outlet,useNavigate, useLocation} from "react-router-dom";
import {useContext, createContext, useEffect, useMemo, useState} from 'react'
import {ArrowBackIosNew} from '@mui/icons-material'
import {Fab} from '@mui/material'

const authContext = createContext({
  user: null,
  isChecking: true,
  setUser: (aUser:any)=>{}
});

export const AuthProvider=({ children }:any) =>{

  const [user, setUser ] = useState<any|null>(null)
  const [isChecking, setIsChecking] = useState(true)

  const context = useMemo(
    () => ({
      user:     user,
      setUser:  setUser,
      isChecking: isChecking,
    }),
    [user, isChecking]
  );

  useEffect(()=>{
    console.log('AuthProvider ...')
    new Promise(async()=>{
      const user = await Auth.currentUserInfo()
      // const user = Auth.currentAuthenticatedUser({bypassCache: true})
      setUser(user)
      setIsChecking(false)
    }).catch((reason)=>{
      console.log(reason)
      setIsChecking(false)
    })
  }, [])

  return (
    <authContext.Provider value={context}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
}

export const PrivateRoute = () =>{
  const location = useLocation()
  let auth = useAuth();
  if(auth.isChecking){
    return <div> Checking ...</div>
  }
  if( !location ){
    return null
  }

  return (
    auth.user ?
      <Outlet/>:
      <Navigate to={{ pathname: "/login", }} state={{ from: location }} />
  )
}

export const FabNavigateBack = ()=>{
  const navigate = useNavigate()

  return (
    <Fab  variant='circular' color="inherit" size='small'
          sx={{ position: 'fixed', top: 16, left: 16, }}
          onClick={()=>{ navigate(-1) }}
    >
      <ArrowBackIosNew/>
    </Fab>
  )
}
