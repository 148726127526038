import { useEffect } from 'react'
import {
  OutlinedInput,
  TextField, InputAdornment, FormControl,Box, Stack }   from '@mui/material'
import { CocoSlotType, MultiNumberData } from './lineDefines'

export const UserResponseInput = ({
  lineState,
  responseValue,
  setResponseValue,
  slot
}:any) =>{

  useEffect( ()=>{
    if(!lineState.slotType){
      return
    }

    if(lineState.slotType.type === CocoSlotType.MultiNumber){
      const keys = Array.from(Array(lineState.slotType.config.multiNumberTotal).keys());
      const vals = keys.reduce((accumulated, current)=>{
        const res = {...accumulated}
        const current_key = `#${current+1}`
        res[current_key] =  slot?slot.value.data[current_key]:0
        return res
      }, {} as {[id:string]: number})

      setResponseValue( {
        type: CocoSlotType.MultiNumber,
        data: vals
      } )
    }
    else if(lineState.slotType.type === CocoSlotType.SimpleNumber){
      setResponseValue( {
        type: CocoSlotType.SimpleNumber,
        data: slot?slot.value.data: 0
      } )
    }
    else if(lineState.slotType.type === CocoSlotType.PlainText){
      setResponseValue( {
        type: CocoSlotType.PlainText,
        data: slot?slot.value.data: ""
      } )
    }
  },[lineState, slot, setResponseValue])

  if(!lineState.slotType){
    // old version
    return (
      <FormControl sx={{ }} required >
        <OutlinedInput placeholder="Your order" name='comment' />
      </FormControl>
    )
  }

  if(lineState.slotType.type === CocoSlotType.PlainText){
    // return (
    //   <FormControl sx={{ }} required >
    //     <OutlinedInput placeholder="Your order" name='comment' />
    //   </FormControl>
    // )
    if(!responseValue){
      return <Box>Loading...</Box>
    }
    return (
      <TextField id="comment"  name='comment' required
        disabled  = {!lineState.is_open}
        label="My Order"
        placeholder="x N "
        defaultValue={responseValue?.data??""}
        onChange={(e)=>{
          e.preventDefault();
          if(responseValue){
            responseValue.data = e.target.value
            setResponseValue(responseValue)
          }
        }}
        />
    )
  }
  else if(lineState.slotType.type === CocoSlotType.SimpleNumber){
    if(!responseValue){
      return <Box>Loading...</Box>
    }
    return <TextField id="quantity"  name='comment' required
            disabled  = {!lineState.is_open}
            label="Quantity"
            placeholder="Quantity"
            // defaultValue="0"
            // helperText="..."
            inputProps={{
              inputMode: 'numeric',
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">x</InputAdornment>
            }}
            defaultValue={responseValue?.data??0}
            // value = {responseValue.data}
            onChange={(e)=>{
              e.preventDefault();
              if(responseValue){
                responseValue.data = parseInt(e.target.value)
                setResponseValue(responseValue)
              }
            }}
            />
  }
  else if(lineState.slotType.type === CocoSlotType.MultiNumber){
    if(!responseValue?.data){
      return <Box>Loading...</Box>
    }
    const data = (responseValue?.data as MultiNumberData)
    const updateMultiNumValue = ({key, value}: any) =>{
      if(responseValue){
        (responseValue.data as MultiNumberData)[key]=parseInt(value)
        setResponseValue(responseValue)
      }
    }

    return (
        <Stack direction="column" spacing={2} >
          {
            Object.entries(data).map(([id,value])=>{
              return(
                <TextField id={"quantity"+id} key={"quantity"+id} name={"quantity"+id} required
                  disabled  = {!lineState.is_open}
                  label       ="Quantity"
                  placeholder ="Quantity"  // helperText="..."
                  defaultValue={value}
                  inputProps  ={{ inputMode: 'numeric',}}
                  InputProps  ={{
                    startAdornment: <InputAdornment position="start">{id} x</InputAdornment>
                  }}
                  onChange={(e)=>{
                    e.preventDefault();
                    updateMultiNumValue({key: id, value: e.target.value})
                  }}
                  />
              )
            })
          }
        </Stack>
    )

  }

  return <OutlinedInput placeholder="Your order" name='comment' />
}
