import React, {useState, useEffect}   from 'react'
import {Typography, Avatar, Grid} from '@mui/material'
import dataProv                   from '../dataProvider'

export type StoreBannerData = {
  storeName?:  string,
  storeDesc?:  string,
  avatarSrc?:  string,
  storeId?:     string,
}

export const StoreInfoFragment = ({
  // storeName,
  // storeDesc,
  // avatarSrc,
  storeId,
}: {storeId: string}) => {

  // const handleAvatarClicked = ()=>{
  //   navigate(`/host/${line.user_id}`, {
  //     state: {
  //      ...
  //     }
  //   })
  // }
  const [viewModel, setViewModel ] = useState<StoreBannerData>({})

  useEffect(()=>{

    new Promise( async()=>{
      const result = await dataProv.getOne(
        'stores', {
          id: storeId
        }
      )

      console.log(result)
      setViewModel({
        storeId:    storeId,
        storeName:  result.data.store_name,
        storeDesc:  result.data.desc,
        avatarSrc:  result.data.avatarFile.src
      })

    })
  }, [])

  return (
    <React.Fragment>
      <Typography variant='h1' sx={{
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingTop: '15px',
        minWidth: { xs: 350, md: 250 },
      }}>
      <a href={`/store/${storeId}`}>
        {viewModel.storeName}
      </a>
      </Typography>
      <Typography variant='body1' sx={{
        textAlign: 'center',
        paddingX: '25px',
        color: '#666666',
        minWidth: { xs: 350, md: 250 },
      }}>
        {viewModel.storeDesc}
      </Typography>
      <Avatar alt="Host" src={viewModel.avatarSrc??'..'} style={{
        width: 96, height: 96, margin: 10,
      }} />
      <Grid item>
      </Grid>
    </React.Fragment>
  )
}
