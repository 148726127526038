import React from 'react'
import {Typography, Avatar, Grid} from '@mui/material'

export type HostBannerData = {
  groupName?:  string,
  groupDesc?:  string,
  avatarSrc?:  string,
  hostId?:     string,
}

export const HostInfoFragment = ({
  groupName,
  groupDesc,
  avatarSrc,
  hostId,
}: HostBannerData) => {

  // const handleAvatarClicked = ()=>{
  //   navigate(`/host/${line.user_id}`, {
  //     state: {
  //      ...
  //     }
  //   })
  // }

  return (
    <React.Fragment>
      <Typography variant='h1' sx={{
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingTop: '15px',
        minWidth: { xs: 350, md: 250 },
      }}>
      <a href={`/host/${hostId}`}>
        {groupName}
      </a>
      </Typography>

      <Typography variant='body1' sx={{
        textAlign: 'center',
        paddingX: '25px',
        color: '#666666',
        minWidth: { xs: 350, md: 250 },
      }}>
        {groupDesc}
      </Typography>

        <Avatar alt="Host" src={avatarSrc??'..'} style={{
          width: 96, height: 96, margin: 10,
        }} />
      <Grid item>
      </Grid>
    </React.Fragment>
  )
}
