export default {
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://pl1kf3daji.execute-api.ap-southeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_WVK7ItDBF",
    APP_CLIENT_ID: "1ssh931a0hltc1avupd058vmt8",
    IDENTITY_POOL_ID: "ap-southeast-1:dc16f70a-b034-40e2-93a9-da553c618bfe"
  },
  cognito_old: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_bMoDi5q4B",
    APP_CLIENT_ID: "1ptcmebhtqq758a5blljuovo61",
    IDENTITY_POOL_ID: "ap-southeast-1:449cddfe-6887-424e-8b1d-232aae470ba5"
  }
};
