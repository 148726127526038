import { Amplify }            from 'aws-amplify';
import { useEffect }          from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {ConfirmProvider}      from 'material-ui-confirm';
import { SnackbarProvider }   from 'notistack';

import './index.css';
import {Register} from './account/register'
import {Login}    from './account/login'
import reportWebVitals from './reportWebVitals';

import {HostHome}     from './host/hostHome'
import {SellerHome}   from './seller/SellerHome'
import {Product}      from './seller/Product'
import {CocoLine}     from './line/line'
import {CocoHistory}  from './history/history'
import {Settings}      from './account/settings'
import {PrivateRoute, AuthProvider, useAuth}  from './utils/route'
import {GlobalDebug}   from './utils/globalDebug'

import config           from './amplify-config';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH',
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "coco-user",
        endpoint: 'https://7limrutlv9.execute-api.ap-southeast-1.amazonaws.com/prod',
        region: config.apiGateway.REGION
      },
      {
        name: "coco-s3-upload",
        endpoint: "https://gt6682twqe.execute-api.ap-southeast-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      }
    ]
  }
});
Amplify.Logger.LOG_LEVEL = "WARN";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MyBrowerRouter = ()  => {

  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false, false);
  }, []);

  const auth = useAuth()
  if(auth.isChecking){
    return <div> checking ...</div>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/line">
          <Route path=":lineId" element={<CocoLine />} />
        </Route>
        <Route path="/host">
          <Route path=":hostId" element={<HostHome />} />
        </Route>
        <Route path="/store">
          <Route path=":storeId" element={<SellerHome />} />
        </Route>
        <Route path="/product">
          <Route path=":productId" element={<Product />} />
        </Route>
        <Route path="/register"  element={auth.user?<Navigate to="/settings" />:<Register />} />
        <Route path="/login"     element={auth.user?<Navigate to="/settings" />:<Login />} />
        {
          <Route  path='/' element={<PrivateRoute/>} >
              <Route path="history"  element={<CocoHistory />} />
              <Route path="settings"  element={<Settings />} />
              <Route path='*' element={<Navigate to="/history" />} />
              <Route path='/' element={<Navigate to="/history" />} />
          </Route>
        }
        <Route path='/*' element={<Navigate to="/register" />} />
      </Routes>
    </BrowserRouter>
  )
}

root.render(
  // <React.StrictMode>
  <AuthProvider>
    <ConfirmProvider>
    <SnackbarProvider>
      <MyBrowerRouter/>
    </SnackbarProvider>
    </ConfirmProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
