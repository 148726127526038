import {
  Snackbar, Alert, AlertColor,
} from '@mui/material'

export type SnackbarInfo ={
  message:  string,
  severity: AlertColor,
}

export const MySnackBar =({
  open, setOpen, info, anchorOrigin
}: any)=>{

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}
      anchorOrigin={anchorOrigin??{ vertical:'bottom', horizontal:'center' }}
    >
    <Alert  onClose={handleClose} severity={info.severity} sx={{ width: '100%' }}>
      {info.message}
    </Alert>
  </Snackbar>
}
