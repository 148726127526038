import {
  Slide,
  useScrollTrigger
} from '@mui/material'

import {CocoSlotType} from './lineDefines'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}


export function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  // return React.cloneElement(children, {
  //   elevation: trigger ? 4 : 0,
  // });
  return (
    <Slide appear={true} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

export async function copyTextToClipboard(text:string, element: any) {
  if ('clipboard' in navigator) {
    console.log('a')
    return await navigator.clipboard.writeText(text);
  } else {
    var clipBoardElem = document.createElement("textarea");

    // clipBoardElem.hidden = true
    element.appendChild(clipBoardElem);
    clipBoardElem.value = text
    clipBoardElem.select();
    // var successfulCopy = 
    document.execCommand('copy');
    element.removeChild(clipBoardElem)
  }
}

const slotResponseDisplayText = (slot: any)=>{

  if(slot.value.type === CocoSlotType.MultiNumber){
    return ' ' + Object.entries(slot.value.data).map(([id,value]: any)=>{
      return `${id} x ${value}`
    }).join(', ')
  }
  else if(slot.value.type === CocoSlotType.SimpleNumber){
    return `x ${slot.value.data}`
  }
  else if(slot.value.type === CocoSlotType.PlainText){
    return ` ${slot.value.data}`
  }
}

export const generateWhatsAppMsg =(slots: any) => {
  const cms = slots.map((s:any)=>`${s.order}. ${s.nick_name} : ${slotResponseDisplayText(s)}`).join('\n')
  const msg = `${window.location.href}\n${cms}`;

  return msg
}

export const openWhatsappToPaste = async(slots: any, htmlElem: any, whatsAppLink: string) => {

  const msg = generateWhatsAppMsg(slots);
  console.log({msg})
  await copyTextToClipboard(msg, htmlElem)
  // window.open('https://chat.whatsapp.com/Dj4nlBDLAAS3GoRplCgYWq', '_blank')
  // window.open('whatsapp://chat?code=Dj4nlBDLAAS3GoRplCgYWq', '_blank')
  window.open(whatsAppLink, '_blank')
}
