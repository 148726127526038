export enum CocoSlotType{
  PlainText     = 'plain-text',
  SimpleNumber  = 'simple-number',
  MultiNumber   = 'multi-number',
}

export interface MultiNumberData { [id:string]: any }

export type CocoSlotValue = {
  type: CocoSlotType
  data: MultiNumberData  | number | string
}
