
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import SendToMobileIcon   from '@mui/icons-material/SendToMobile';
import { Auth, }          from "aws-amplify";
import { CognitoUser,  }  from 'amazon-cognito-identity-js';

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CircularProgress, Backdrop } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright }                  from './copyright'
import {FabNavigateBack, useAuth}     from '../utils/route'
import { useSnackbar } from 'notistack';

const theme = createTheme();

enum SigninPhase{
  Init,
  SendingOTP,
  OTPSent,
  OTPVerifying,
  OTPVerified
}

interface SigninSteps{
  phase:      SigninPhase;
  phoneNum?:  string;
  user?:      CognitoUser;
}

export const Login =() => {

  const [step, setStep] = useState<SigninSteps>({phase: SigninPhase.Init})
  // const [verifyCode, setVerifyCode] = useState<string>('')
  const [phoneNum, setPhoneNum] = useState<string>('')
  const { enqueueSnackbar, }    = useSnackbar();
  const navigate  = useNavigate();
  const location: {[id:string]: any} = useLocation()
  const {setUser} = useAuth()
  if( !location ){
    return null
  }
  console.log(location)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
    event.preventDefault();
    console.dir(event)
    const data = new FormData(event.currentTarget);
    console.dir(data)
    console.dir(step)

    if( step.phase === SigninPhase.Init ){
      const inputs = {
        phone: data.get('phoneNum'),
        password: data.get('password'),
      }
      console.log(inputs);
      const phoneNum = ('+65' + data.get('phoneNum'))?.toString()??"";

      try {
        setStep( {phase: SigninPhase.SendingOTP, phoneNum: phoneNum} );

        const user = await Auth.signIn(phoneNum);
        console.dir(user);

        setStep({phase: SigninPhase.OTPSent, phoneNum: phoneNum, user: user});
        console.log(user);
      } catch (error: any) {
          console.log('error signing up:', error);
          console.dir(error)

          enqueueSnackbar('error signing in: ' + error, { variant: 'warning' });
          setStep( {phase: SigninPhase.Init, phoneNum: phoneNum} );
      }
    }
    else if( step.phase === SigninPhase.OTPSent || step.phase === SigninPhase.OTPVerifying ){

      console.log("to verify")

      const phoneNum = step.phoneNum ?? ""
      console.log({
        phoneNum: phoneNum, // data.get('phoneNum'),
        verifyCode: data.get('verifyCode'),
      })
      console.log(phoneNum)

      try
      {
        setStep({phase: SigninPhase.OTPVerifying, phoneNum: phoneNum, user: step.user});
        // const user = await Auth.currentUserPoolUser();
        // console.dir(user);

        const userName = phoneNum // ('+65' + data.get('phoneNum'))?.toString()??"";
        const verifyCode = data.get('verifyCode')?.toString()??""
        console.log({
          userName: userName,
          verifyCode: verifyCode
        });
        console.dir(step)

        const res = await Auth.sendCustomChallengeAnswer(
          step.user, verifyCode
        )
        console.dir(res)
        //const authUser =
        await Auth.currentAuthenticatedUser();
        setStep({phase: SigninPhase.OTPVerified, phoneNum: phoneNum});
        setUser(await Auth.currentUserInfo())

        let navTo='/'
        if(location.state && location.state.from.pathname !== location.pathname ){
          navTo = location.state.from
        }
        setTimeout(() => navigate(navTo, {replace: true}), 2000);
      } catch (error) {
          console.log('error confirming sign in: ', error);
          enqueueSnackbar('error signing in: ' + error, { variant: 'warning' });
          setStep({...step, phase: SigninPhase.OTPSent})
      }
    }

  };

  // function onChange(value: any) {
  //   console.log("Captcha value:", value);
  // }

  const BtnSendOTP = (props: any) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      console.log(token.slice(0, 20))

    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return <Button
      type="submit"
      {...props}
      fullWidth
      variant="contained"
      endIcon={<SendToMobileIcon />}
      onClick={handleReCaptchaVerify}
    >
      Send OTP
    </Button>;
    // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldt9CsgAAAAAEfXA5XP6Ro_G8TfIAfFFhICfSxO">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Box component="img"
              sx={{
                height: '170px',
                paddingY: '20px',
              }}
              alt="logo"
              src="/icons/banner-6.png"
            />
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextField required fullWidth id="phoneNum" label="Phone Number" name="phoneNum"
                      type='number'     disabled={step.phase !== SigninPhase.Init}       value={phoneNum}
                      onChange={(e:any)=>{
                        e.preventDefault();
                        if(e.target.value.length > 8 ){
                          return
                        }
                        setPhoneNum(e.target.value)
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>
                                        <BtnSendOTP disabled={step.phase !== SigninPhase.Init|| (phoneNum.length !== 8)} />
                                      </InputAdornment>
                      }}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                    />
                </Grid>
                { (step.phase === SigninPhase.OTPSent || step.phase === SigninPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="verifyCode"
                      // value={verifyCode}
                      label="Verification Code"
                      name="verifyCode"
                      type='number'
                      disabled={false}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>

                                      </InputAdornment>

                      }}
                      // onChange={e => setVerifyCode(e.target.value)}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                    />
                  </Grid>
                }
                { (step.phase === SigninPhase.OTPSent || step.phase === SigninPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 0, mb: 2 }}
                      disabled={step.phase === SigninPhase.OTPVerifying}
                    >
                      Login
                    </Button>
                  </Grid>
                }
                { (step.phase === SigninPhase.OTPVerified )&&
                  <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={true}
                    >
                    <Grid item xs={12}>
                      <Typography component="h1" variant="h5" align='center' sx={{ mt: 0, mb: 2 }}
                      >
                        Loggined !
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <CircularProgress/>
                      </Box>
                    </Grid>
                  </Backdrop>
                }
              </Grid>
              <Grid container justifyContent="flex-end" padding='8px'>
                <Grid item>
                  <Link variant="body2" sx={{fontSize:'16px'}}
                    onClick={(e)=>{
                      e.preventDefault()
                      navigate('/register', {
                          replace: true,
                          state: location.state?.from? {from: location.state.from}:null
                      })
                    }}
                    >
                    Don't have an account? Register
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
        <FabNavigateBack/>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}
