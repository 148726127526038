import { Auth }                 from 'aws-amplify';
import { ICredentials }         from '@aws-amplify/core';

import {
  Timeline, TimelineItem, TimelineSeparator,
  TimelineConnector,TimelineContent,
  TimelineDot, TimelineOppositeContent,}    from '@mui/lab'
import {
  Fastfood, MoreVert,
  CheckCircleOutlineSharp,Send, WhatsApp }  from '@mui/icons-material';
import {
  Box, Grid,Button, IconButton, FormControl, Stack,
  OutlinedInput,ThemeProvider, Typography,
  Avatar,Fab, Chip,Divider,CircularProgress, createTheme,} from '@mui/material'

import * as React               from 'react'
import { useEffect, useState }  from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PageVisibility           from 'react-page-visibility';
import {useConfirm }              from 'material-ui-confirm';
// @ts-ignore
import {last}                     from 'lodash/array';

import dataProv                 from '../dataProvider'
import {HostInfoFragment}       from './hostInfoFragment'
import {UserResponseInput}      from './lineUserResponseInput'
import {CocoSlotValue,   CocoSlotType } from './lineDefines'
import {LineState,}               from './lineContext'
import {LineTopBar}               from './lineTopbar'
import {SendingDialog, FabDialog} from './lineDialogs'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu, FabMenuUnAuth}   from '../utils/fabMenu'

export const CocoLine = () =>{

  const [line, setLine] = useState<LineState&any>({
    title:'Loading...',
    desc:'Loading...',
    medias:[],
    host:{},
    is_open: true,
    whatsapp_group: {}
  })
  const [slots, setSlots]               = useState<{[id:string]:any}[]>([])
  const [identityId,   setIdentityId]   = useState<string>()
  const [userInfo,     setUserInfo]     = useState<any|undefined>()
  const [credentials,  setCredentials]  = useState<ICredentials|undefined>()
  const [histUserIds,  setHistUserIds]  = useState<{[id:string]: string}>({})
  const [isUserInLine, setIsUserInLine] = useState<boolean>(false)
  const [nickName, setNickName]         = useState<string>('')
  const [refreshCount, setRefreshCount] = useState(0)

  const [responseValue, setResponseValue] = useState<CocoSlotValue|null>(null);

  const [showSendingDialog, setShowSendingDialog]   = useState(false)
  const [isSending, setIsSending] = useState(false)

  const [showFabDialog, setShowFabDialog] = useState(false)
  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo,  setSnackBarInfo]  = useState<SnackbarInfo>({
    message: "default msg", severity: 'success'
  })

  const [updatingPrevSlot,  setUpdatingPrevSlot]  = useState(false)


  let   params = useParams();
  const navigate = useNavigate();
  const location = useLocation()

  const contentWidth = 260;
  const showOppositeContent = false;

  useEffect(()=>{

    new Promise(async()=>{

      const uinfo = await Auth.currentUserInfo()
      setUserInfo(uinfo)
      console.log(uinfo)
      const credentials = await Auth.currentCredentials()
      setCredentials(credentials)

      let theIdentityId= null
      if(uinfo){
        theIdentityId = "a:" + uinfo.attributes.sub
        setNickName(uinfo.attributes.nickname)
        localStorage.setItem('nickname', uinfo.attributes.nickname)

        const historyUserIds: any = JSON.parse(localStorage.getItem('historyUserIds')??'{}')
        historyUserIds[uinfo.attributes.sub] = (new Date()).toISOString()
        localStorage.setItem('historyUserIds', JSON.stringify(historyUserIds))
      }
      else{
        console.log({credentials})
        theIdentityId = credentials.identityId
        const nname = localStorage.getItem('nickname')
        if(nname){
          setNickName(nname);
        }
        setHistUserIds( JSON.parse(localStorage.getItem('historyUserIds')??'{}') )
      }
      setIdentityId(theIdentityId)

      const lineResult = await dataProv.getOne('lines', {id: params.lineId??'abcxyz'});
      console.log(lineResult)
      setLine( {
        ...lineResult.data,
        title: lineResult.data.is_open?
                lineResult.data.title : '[Closed]' + lineResult.data.title
      })

      setSlotsUsingLineData(lineResult.data,
        setSlots,
        theIdentityId,
        credentials,
        uinfo,
        setUpdatingPrevSlot)

    });

  },[refreshCount, params.lineId])

  const refresh = ()=>{
    setRefreshCount(refreshCount+1)
  }

  const setSlotsUsingLineData = (
    lineData: any,
    setSlots: any,
    identityId: string,
    credentials: any,
    uInfo: any,
    setUpdatingPrevSlot: any,
  ) => {
    const lineSlots = [...lineData.slots]
    lineSlots.sort((a,b)=>{
      return (new Date(a.create_time)).getTime() - (new Date(b.create_time)).getTime()
    })

    const [, user_uuid] = identityId.split(':')??[null, null]
    const theSlots = []
    let index = 1
    for(const slot of lineSlots.filter(x=>x.comment.length> 0)){
      theSlots.push({
        ...slot,
        order: index++
      })
      const slot_user_uuid = last(slot.user_id.split(':'))

      if( user_uuid && (
          user_uuid === slot_user_uuid ||
          credentials?.identityId.split(':')[1] === slot_user_uuid
        )
      ){
        setIsUserInLine(true);
      }

      //
      //    User register/login right after submit a slot,
      //    In this case the slot's user_id still equal to credentials.identityId
      //    It's a good time to update the slot so that it's user_id sync to
      //    authorized user's user id
      //
      if(uInfo && credentials?.identityId.split(':')[1] === slot_user_uuid){
        setUpdatingPrevSlot(true)
        new Promise(async()=>{

          const updateData = {
            comment:    slot.comment,
            nick_name:  uInfo.attributes.nickname,
            value:      slot.value,
            lineId:     lineData.id,
          }
          console.log(updateData)
          await dataProv.update('slots', {
            id:   slot.id,
            data: updateData
          }).catch((reason)=>{
            console.error(reason)
          })
          setUpdatingPrevSlot(false)
          refresh()
        })
      }
    }

    setSlots(theSlots);
  }

  const handleCreate = async (
    event: React.FormEvent<HTMLFormElement>,
    confirm: any
  )  => {
    event.preventDefault();

    // console.log(confirm)
    // await confirm({
    //   title: 'Confirm',
    //   description: 'Register / Login for better experiences ',
    //   confirmationText: 'Register / Login ',
    //   cancellationText: 'Proceed without login '
    // }).then(() => {
    //   // setEnableEdit(!enableEdit)
    // }).catch(() => {
    //   /* ... */
    // });
    // return

    setShowSendingDialog(true)
    setIsSending(true)

    const data = new FormData(event.currentTarget);
    if(data.get('nick_name')){
      localStorage.setItem('nickname', data.get('nick_name')?.toString()??"")
    }
    else{
      console.log('no nick name set')
    }
    const fieldValues:{[key:string]: any} = {}
    data.forEach((val:any, key:string)=>{
      console.log(`${key} = ${val}`)
      if(key.startsWith('quantity#')){
        // do nothing
      }
      else
      {
        fieldValues[key]=val
      }
    })

    const res = await dataProv.create('slots', {
      data: {
        ...fieldValues,
        value:    responseValue,
        lineId:   params.lineId??'abcxyz',
      }
    }).catch((reason)=>{
      console.log(reason)
      setIsSending(false)
      setShowSendingDialog(false)
      setSnackBarInfo({message:'From server: ' + reason , severity: 'error'})
      setShowSnackBar(true)
    })
    console.log({res: res})
    refresh()

    setIsSending(false)
  }

  const handleUpdate = async (
    event:  React.FormEvent<HTMLFormElement>,
    slot:   any
  )  => {
    const data = new FormData(event.currentTarget);
    console.log(data)
    console.log(responseValue)

    setShowSendingDialog(true)
    setIsSending(true)

    const updateData = {
      comment:  data.get('comment'),
      nick_name: nickName,
      value:    responseValue,
      lineId:   params.lineId??'abcxyz',
    }
    console.log({updateData})

    const res = await dataProv.update('slots', {
      id:   slot.id,
      data: updateData
    }).catch((reason)=>{
      console.log(reason)
      setIsSending(false)
      setShowSendingDialog(false)
      setSnackBarInfo({
        message:'From server: ' + reason,
        severity: 'error'
      })
      setShowSnackBar(true)
    })
    console.log({res: res})

    refresh()
    setIsSending(false)
  }

  const handleClose = async (event: React.FormEvent<HTMLFormElement>, slot: any)  => {
    setShowSendingDialog(false)
  }

  return (
    <main style={{  }}>
      <LineTopBar line={line} />
      <Grid container justifyContent="center"  alignItems="center"
            direction="column" spacing={2}
            sx={{bgcolor: '0xEEEEEE', paddingY:"15px" }}
        >
        <HostInfoFragment
            groupName={line.host.groupName}
            groupDesc={line.host.groupDesc}
            avatarSrc={line.host.src}
            hostId={line.user_id} />
        <Grid  item>
          <LinePost line={line} />
          <Divider />
          {
            updatingPrevSlot?
            <Stack direction='column' alignItems='center' py='10px' spacing='15px'>
              <Typography fontSize='18px'>
                Updating ...
              </Typography>
              <CircularProgress/>
            </Stack>:
            <Timeline position="right" sx={{ width:320, marginLeft: -5 }}>
              <TimeLineItemStart line={line}
                                showOppositeContent={showOppositeContent}
                                contentWidth={contentWidth} />
              <TimeLineItemExample line={line}
                                showOppositeContent={showOppositeContent}
                                contentWidth={contentWidth} />

              { identityId&&
                slots.map(slot=>{
                  const [, user_uuid]   = identityId.split(':')??[null, null]
                  const slot_user_uuid = slot.user_id.startsWith('anonymous:')?
                      slot.user_id.split(':')[1]:slot.user_id

                  if( user_uuid && credentials && (
                      user_uuid === slot_user_uuid
                      || credentials?.identityId.split(':')[1] === slot_user_uuid
                    )
                  ){
                    // setResponseValue(slot.value)

                    return <EditItem slot={slot}
                                lineState={line}
                                nickName={nickName}
                                key={slot.id}
                                contentWidth={contentWidth}
                                showOppositeContent={false}
                                responseValue={responseValue}
                                setResponseValue={setResponseValue}
                                onSubmit={(e:any)=>{
                                  e.preventDefault();
                                  handleUpdate(e, slot);
                                }}
                            />
                  }else{
                    return <SlotTimelineItem slot={slot} key={slot.id}
                                contentWidth={contentWidth} showOppositeContent={false}/>;
                  }
                })
              }
              { (!isUserInLine) &&
                  (
                    (Object.entries(histUserIds).length>0 ||
                      (line.loginToPostResponse === true && userInfo === undefined))?
                    <TimelineItem sx={{ marginTop:'-20px' }} key='line-item-create' >
                      <TimelineSeparator>
                        <TimelineConnector sx={{  }} />
                        <TimelineDot color="secondary" variant='outlined'>
                          <Typography sx={{width:24, textAlign:'center' }}>{slots.length+1}</Typography>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '0px', px: 2, paddingTop: '35px' }}>
                        {
                          <Box sx={{width: contentWidth, height:'60px'}}>
                            Please
                            <Button onClick={()=>{
                              navigate('/login', {state:{ from: location}})
                            }} >login </Button>
                            to create response
                          </Box>
                        }
                      </TimelineContent>
                    </TimelineItem>:
                    <CreateItem userInfo={userInfo}
                      slots={slots} lineState={line} onCreate={handleCreate}
                      contentWidth={contentWidth} showOppositeContent={false}
                      nickName={nickName} setNickName={setNickName}
                      responseValue={responseValue} setResponseValue={setResponseValue}
                      />
                  )
              }
              {
                line &&
                <TimeLineItemEnd  line={line}  showOppositeContent={showOppositeContent}
                                  contentWidth={contentWidth} />
              }

            </Timeline>
          }
          <Box sx={{height:250, width:'auto'}}/>
        </Grid>
      </Grid>

      { userInfo ?
        <FabMenu/>
        :
        <FabMenuUnAuth/>
      }
      <Fab  color="inherit"
        sx={{   position: 'fixed', bottom: 16, right: 16, }}
        onClick={()=>{
          setShowFabDialog(true)
        }}
      >
        <WhatsApp />
      </Fab>

      <SendingDialog open={showSendingDialog}
        isSending={isSending}
        setShowSendingDialog={setShowSendingDialog}
        handleClose={handleClose} slots={slots}
        whatsAppLink={line.whatsapp_group.invitationLink}
      />
      <FabDialog  open={showFabDialog} handleClose={()=>{setShowFabDialog(false)}}
                  whatsAppLink={line.whatsapp_group.invitationLink} slots={slots}
      />

      <PageVisibility onChange={async(isVisible)=>{
        // console.log({isVisible})
        if(isVisible){
          // When show to front
          // try{
          //   const userInfo = await Auth.currentUserInfo();
          //   console.log({userInfo})
          //   // const user = await Auth.currentAuthenticatedUser();
          // }catch(e){
          //   console.log( typeof(e))
          //   console.log('error: ' + e);
          // }
        }
      }}>
      </PageVisibility>
      <MySnackBar open={showSnackBar} setOpen={setShowSnackBar} info={snackBarInfo}/>
    </main>
  );
}

const LinePost = (
  {line}: any
) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant='h5' sx={{
        textAlign: 'center',
        // width: 400,
        paddingBottom: 2,
        minWidth: { xs: 350, md: 250 },
      }}>{line.title}
      </Typography>
      <Grid item>
        { (line.medias.length) > 0 ?
          <Box component="img"
            sx={{
              width: {xs: 350, md: 400},
              minWidth: { xs: 350, md: 250 },
              objectFit: 'contain',
              borderRadius: '5px',
              border: 2,
              borderColor: '#DDDDDD',
            }}
            alt="The house from the offer."
            src={line.medias[0].src}
          />:
          <Box sx={{
              heigh: 300,
              minHeight: 400,
              width: 400,
              verticalAlign: 'center',
              objectFit: 'cover'
            }}
          >
            <Grid container justifyContent="center"
                alignItems="center"
                direction="column">
              <Grid item/> <Grid item/>
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          </Box>
        }
      </Grid>
      <Grid item xs={3}>
        <Box sx={{
          width: {xs:250, md:350},
          minWidth: { xs: 350, md: 250 },
          paddingY: '10px',
          borderRadius: '5%',
        }}>
          <Typography variant="body1" style={{whiteSpace: 'pre-line'}} gutterBottom>
            {line.desc}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

const TimeLineItemStart = (
  {line, showOppositeContent, contentWidth}:
  {line: any, showOppositeContent: boolean, contentWidth: number}
) => {
  return (
    <TimelineItem>
      {showOppositeContent&&
        <TimelineOppositeContent color="text.secondary"> 09:30 am </TimelineOppositeContent>
      }
      <TimelineSeparator sx={{paddingX:1.5}}>
          <TimelineDot  >
            {/* <Fastfood /> */}
          </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '0px', px: 2 }}>
        <Typography variant="h6" component="span" sx={{ py: '0px'}}>
          {
            line.is_open? 'Opening': 'Finished'
          }
        </Typography>
        <Typography sx={{width: contentWidth}}>
          {
            line.is_open? 'Indicate your orders below:': ''
          }
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

const TimeLineItemExample = (
  {line, showOppositeContent, contentWidth}:
  {line: any, showOppositeContent: boolean, contentWidth: number}
) => {
  return (
    <TimelineItem>
      {showOppositeContent&&<TimelineOppositeContent
            sx={{ m: 'auto 0'}}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Box width='2'>
            19:30 am
            </Box>
        </TimelineOppositeContent>
      }
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          <Fastfood />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" sx={{fontSize:'19px'}}>
          {
            line.title
          }
        </Typography>
        {
          <Typography sx={{width:contentWidth}}></Typography>
        }

      </TimelineContent>
    </TimelineItem>
  )
}


const TimeLineItemEnd = (
  {line, showOppositeContent, contentWidth}:
  {line: any, showOppositeContent: boolean, contentWidth: number}
) => {

  let finished_msg  = 'No more response to accepte'
  let ongoing_msg   = 'It\'s ongoing '
  if(line && line.slotType){
    if( CocoSlotType.SimpleNumber === line.slotType.type ){
      const total = line.slots.reduce( (acc: number, slot:any )=>(acc+slot.value.data), 0)
      finished_msg = `Total quantity: ${total}`

      if( line.slotType.config.showMOQ ){
        ongoing_msg = `Current/MOQ :  ${total} / ${line.slotType.config.moq}`
      }
    }
    else if(CocoSlotType.MultiNumber === line.slotType.type ){
      // #### TODO : for multi number
    }
  }

  return (
    <TimelineItem>
        {showOppositeContent&&
          <TimelineOppositeContent color="text.secondary"> 09:30 am </TimelineOppositeContent>
        }
        <TimelineSeparator sx={{
          // paddingX:1.5
          }}>
            <TimelineDot  >
              { line.is_open ?
                <MoreVert />:<CheckCircleOutlineSharp/>
              }
            </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: '15px' }}>
          <Typography variant="h6" component="span" color='GrayText' sx={{
            py: '0px', width: contentWidth,
          }}>
            { line.is_open ?
              "Ongoing" : "Finished"
            }
          </Typography>
          {
            <Typography color='GrayText' sx={{width: contentWidth}}>
              { line.is_open ?
                ongoing_msg : finished_msg
              }
            </Typography>
          }
        </TimelineContent>
    </TimelineItem>
  )
}

const CreateItem =(props: any)=>{
  const { userInfo,
    slots,        lineState, onCreate,
    nickName,     setNickName,
    contentWidth, showOppositeContent,
    responseValue, setResponseValue
  } = props;
  const confirm = useConfirm()

  return <TimelineItem sx={{ marginTop:'-20px' }} key='line-item-create' >
    {showOppositeContent &&<TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        variant="body2"
        color="text.secondary"
      >
        10:00 am
      </TimelineOppositeContent>
    }
    <TimelineSeparator>
      <TimelineConnector sx={{  }} />
      <TimelineDot color="secondary" variant='outlined'>
        <Typography sx={{width:24, textAlign:'center' }}>{slots.length+1}</Typography>
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '0px', px: 2, paddingTop: '35px' }}>
      <Box component="form"
        autoComplete="off"
        onSubmit={
          (e: any)=>{
            e.preventDefault();
            onCreate(e, confirm);
          }
        }
        sx={{width: contentWidth}}>
          <Grid container spacing={0.5} rowSpacing={1.5}>
            <Grid item xs={5} md={5}>
              <FormControl sx={{  }} required >
                <OutlinedInput placeholder="Name"
                  disabled = {!lineState.is_open}
                  name='nick_name' value={nickName}
                  onChange={(e)=>{
                    e.preventDefault();
                    setNickName(e.target.value);
                  }}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={7} md={7}>
              {
                  <UserResponseInput
                    lineState={lineState}
                    responseValue={responseValue}
                    setResponseValue={setResponseValue} />
              }
            </Grid>
            <Grid item xs={5} md={5} lg={5}>
                { //  📱***9078
                  userInfo&& displayPhoneNumber(
                    userInfo.attributes.phone_number)

                }
            </Grid>
            <Grid item xs={7} md={7} lg={7}>
              <Button variant="contained" color='inherit' fullWidth
                  disabled  = {!lineState.is_open}
                  endIcon={<Send />} type='submit'
                  size='large'
              >
                Post
              </Button>
            </Grid>
          </Grid>
        </Box>
    </TimelineContent>
  </TimelineItem>
}

const EditItem =(props: any)=>{
  const {slot, onSubmit, nickName,
    contentWidth, showOppositeContent, lineState,
    responseValue, setResponseValue,
  } = props;
  // const [comment, setComment] = useState<string>(slot.comment)

  return <TimelineItem sx={{}} id={slot.id}>
    {showOppositeContent &&<TimelineOppositeContent
        sx={{
          m: 'auto 0',
          // width:50,lin
        }}
        variant="body2"
        color="text.secondary"
      > 10:00 am
      </TimelineOppositeContent>
    }
    <TimelineSeparator>
      <TimelineConnector sx={{  }} />
      <TimelineDot color="secondary" variant='outlined'>
        <Typography sx={{width:24, textAlign:'center' }}>{slot.order}</Typography>
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '0px', px: 2, paddingTop: '10px' }}>
      <Box component="form"
        autoComplete="off" onSubmit={onSubmit}
        sx={{
          width: contentWidth,
          paddingBottom: '10px'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <Chip
                // avatar={<Avatar>{slot.nick_name.slice(0,1)[0].toUpperCase()}</Avatar>}
                label ={nickName.length>0?nickName:slot.nick_name}
                sx    ={{ fontSize: 17, marginBottom: '5px' }} />
              <Typography>
                { //  📱****1234
                  slot.phone_num?`📱**${slot.phone_num.slice(-3)}`: '📱 🫙 '
                }
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              {
                <UserResponseInput lineState={lineState}
                  slot={slot}
                  responseValue={responseValue}
                  setResponseValue={setResponseValue}
                />
              }
            </Grid>
            <Grid item xs={2} md={2}>
              <IconButton aria-label="save" type='submit' disabled={!lineState.is_open}>
                <Send />
              </IconButton>
            </Grid>
            <Grid item xs={6} md={6}/>
            <Grid item xs={6} md={6}>
            </Grid>
          </Grid>
        </Box>
    </TimelineContent>
  </TimelineItem>
}

const SlotTimelineItem = (props: any)=>{
  const {slot, contentWidth, showOppositeContent} = props
  // console.log(slot)

  return <TimelineItem >
    { showOppositeContent&&<TimelineOppositeContent width='5'
        sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
        19:30 am
      </TimelineOppositeContent>
    }
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot variant='outlined'>
        <Typography sx={{width:24, textAlign:'center' }}>{slot.order}</Typography>
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <ThemeProvider theme={theme}>
        <Box sx={{ width:contentWidth, py: '12px',}}>
          <Stack direction='row' >
            <Stack direction='column' >
              <Chip
                avatar={<Avatar>{slot.nick_name.slice(0,1)[0].toUpperCase()}</Avatar>}
                label ={slot.nick_name??".."}
                sx    ={{ fontSize: 17, marginBottom: '5px' }} />
              {
                displayPhoneNumber(slot.phone_num)
              }
            </Stack>
            <Box>
              <SlotValueDisplay slot={slot}  />
            </Box>
          </Stack>
        </Box>
      </ThemeProvider>
    </TimelineContent>
  </TimelineItem>

}

const SlotValueDisplay = ({slot}:any)=>{

  let slotString = slot.comment;

  if(slot.value.type === CocoSlotType.MultiNumber){

    // IF pure text :
    // slotString = Object.entries(slot.value.data).filter((x:any)=>x[1]>0).map(
    //   ([id,value]: any)=>{
    //   return `${id}x${value}`
    // }).join(' , ')

    return (
      <Typography component="span" sx={{py: '12px', px: '5px', fontSize:18, paddingLeft:'5px' }} >
        {
          Object.entries(slot.value.data).filter((x:any)=>x[1]>0).map(([id,value]: any)=>{
            return  <Chip label={`${id} x ${value}`} key={id}
                          sx={{ fontSize: 17, backgroundColor: '#F0F0F0', margin:'2px' }} />
          })
        }
      </Typography>
    )
  }

  return(
    <Typography sx={{py: '12px', px: '5px', fontSize:18, paddingLeft:2 }} >
      {
        slot.value.type === CocoSlotType.SimpleNumber?
        `x ${slotString}`:
        `${slotString}`
      }
    </Typography>
  )
}

const displayPhoneNumber = (phoneNum: string|any) => {
  return (
    <Typography>
    {
      phoneNum?  `📱****${phoneNum.slice(-4)}` : '📱 N.A.'
    }
    </Typography>
  )
}

const inlineStyles = () => ({
  '&': {
    display: 'inline',
  },
});

const theme = createTheme({
  typography: {
    allVariants: {
      ...(inlineStyles() as any),
    },
    // subtitle1: { display: 'inline' },
    // subtitle2: { display: 'inline' },
    // body1: { display: 'inline' },
    // body2: { display: 'inline' },
    // button: { '&': { display: 'inline' } },
    // caption: { '&': { display: 'inline' } },
    // overline: { '&': { display: 'inline' } },
  },
});
