import { Auth }         from "aws-amplify";
import { CognitoUser,  } from 'amazon-cognito-identity-js';
import * as React       from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation }  from "react-router-dom";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha  }         from 'react-google-recaptcha-v3';

import { createTheme, ThemeProvider}   from '@mui/material/styles';
import {
  Button, Container, Link,
  Typography,CssBaseline, TextField,
  Grid, Box, InputAdornment,}   from '@mui/material';
import { SendToMobile,}           from '@mui/icons-material'
import { useSnackbar }            from 'notistack';
import { Copyright }              from './copyright'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabNavigateBack, useAuth} from '../utils/route'
const theme = createTheme();

export const Register = ()=>{

  enum SignupPhase{
    Init,
    SendingOTP,
    OTPSent,
    OTPVerifying,
    OTPVerified
  }

  interface SignupSteps{
    phase: SignupPhase;
    phoneNum? :string;
    user?: CognitoUser|null;
  }

  type ValidityState ={
    isEdited: boolean
    isValid:  boolean
    message?: string
  }

  const useAutoConfirm = true
  const navigate = useNavigate();
  const [step, setStep] = useState<SignupSteps>({phase: SignupPhase.Init})
  // const [verifyCode, setVerifyCode] = useState<string>('')
  // const [phoneNum, setPhoneNum] = useState<string>('')
  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo,  setSnackBarInfo]  = useState<SnackbarInfo>({message: "default msg", severity: 'success'})
  const { enqueueSnackbar } = useSnackbar();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [fieldValidity, setFieldValidity] = useState<{[id:string]: ValidityState}>({})
  const {setUser}   = useAuth()
  const location: {[id: string]: any}     = useLocation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if( step.phase === SignupPhase.Init ){

      const phoneNum = ('+65' + data.get('phoneNum'))?.toString()??"";
      console.log(data.get('nickName')?.toString().length??0)
      if((data.get('nickName')?.toString().length??0) < 3 ){
        setSnackBarInfo({message:'Name must at lease 3 character', severity: 'error'})
        setShowSnackBar(true)
        return
      }
      if(data.get('postalCode')?.toString().length !== 6 ){
        setSnackBarInfo({message:'Postal code must be 6 digit', severity: 'error'})
        setShowSnackBar(true)
        return
      }
      if(data.get('phoneNum')?.toString().length !== 8 ){
        setSnackBarInfo({message:'Phone number must be 8 digit', severity: 'error'})
        setShowSnackBar(true)
        return
      }
      try {
        setStep( {phase: SignupPhase.SendingOTP, phoneNum: phoneNum} );

        const signUpParams = {
            username: phoneNum,
            password: data.get('password')?.toString()??"passwordless-use-sms-instead",
            attributes: {
              phone_number:   phoneNum,
              name:           phoneNum,
              nickname:       data.get('nickName'),
              'custom:postal_code': data.get('postalCode'),
            }
        }
        console.log({signUpParams})
        const { user } = await Auth.signUp(signUpParams).catch(
          async(error)=>{
            console.dir(error)
            if( error.code === "UserLambdaValidationException" ){
              throw(error.message.slice("PreSignUp failed with error".length))
            }
            throw(error)
          }
        )??{user: null }
        console.log(user)

        const userSignIn = useAutoConfirm? await Auth.signIn(phoneNum): user;
        setStep({phase: SignupPhase.OTPSent, phoneNum: phoneNum, user: userSignIn});

        console.log(userSignIn);
      } catch (error: any) {
          console.log('error signing up:', error);

          setSnackBarInfo({message:error.toString(), severity: 'error'})
          setShowSnackBar(true)

          setStep({phase: SignupPhase.Init, phoneNum: phoneNum});
      }
    }
    else if( step.phase === SignupPhase.OTPSent ){

      console.log("to verify")
      const phoneNum = step.phoneNum ?? ""
      console.log({
        phoneNum: phoneNum, // data.get('phoneNum'),
        verifyCode: data.get('verifyCode'),
      })
      console.log(phoneNum)

      try
      {
        setStep({phase: SignupPhase.OTPVerifying, phoneNum: phoneNum});
        const userName = phoneNum // ('+65' + data.get('phoneNum'))?.toString()??"";
        const verifyCode = data.get('verifyCode')?.toString()??""

        const res = useAutoConfirm?
            await Auth.sendCustomChallengeAnswer(step.user, verifyCode):
            await Auth.confirmSignUp(userName, verifyCode);
        console.dir(res)

        // Since bug https://github.com/aws-amplify/amplify-js/issues/10097
        // Method 1:
        // Show MSG to user :
        // You have successfully verified your account, for security purposes, please sign in to access the service.
        //
        // Method 2:
        // check again
        // const authUser =
        await Auth.currentAuthenticatedUser();
        setUser(await Auth.currentUserInfo())

        setStep({phase: SignupPhase.OTPVerified, phoneNum: phoneNum});
        let navTo='/'
        if(location.state && location.state.from.pathname !== location.pathname ){
          navTo = location.state.from
        }
        setTimeout(() => navigate(navTo), 2000);
      } catch (error) {
          console.log('error confirming sign up', error);
          enqueueSnackbar(''+error, { variant: 'warning' });
          setStep({...step, phase: SignupPhase.OTPSent} )
      }
    }

  };

  // function onChange(value: any) {
  //   console.log("Captcha value:", value);
  // }

  const BtnSendOTP = (props: any) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      console.log(token.slice(0, 20))


    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return <Button
      type="submit"
      {...props}
      fullWidth
      variant="contained"
      endIcon={<SendToMobile />}
      onClick={handleReCaptchaVerify}
    >
      Send OTP
    </Button>;
    // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldt9CsgAAAAAEfXA5XP6Ro_G8TfIAfFFhICfSxO">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="img"
              sx={{
                height: '170px',
                paddingY: '30px',
              }}
              alt="logo"
              src="/icons/banner-6.png"
            />
            <Typography component="h1" variant="h5">
              Free Register
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container justifyContent="flex-end" padding='8px'>
                <Grid item>
                  <Link  variant="body2" sx={{fontSize:'16px'}}
                        onClick={(e)=>{
                          //  href="/login"
                          e.preventDefault()
                          navigate('/login', {replace: true})
                        }}
                      >
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="nickName"
                    required
                    disabled={step.phase !== SignupPhase.Init}
                    fullWidth
                    inputProps={{ maxLength: 25 }}
                    id="nick-name"
                    label="Name"
                    defaultValue={localStorage.getItem('nickname')??""}
                    helperText="Your name or nickname"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={step.phase !== SignupPhase.Init}
                    fullWidth
                    id="postal-code"
                    label="Postal Code"
                    name="postalCode"
                    autoComplete="postal-code"
                    type="number"
                    inputProps={{ maxLength: 6 }}
                    placeholder='xxxxxxxx'
                    onChange={(e)=>{
                      e.preventDefault();
                      // updateMultiNumValue({key: id, value: e.target.value})
                      if(!('postalCode'in fieldValidity)){
                        setFieldValidity({
                          ...fieldValidity,
                          ...{postalCode: {isEdited: true, isValid: false }}
                        })
                      }
                      else{
                        if(e.target.value.length !== 6){
                          setFieldValidity({
                            ...fieldValidity,
                            ...{postalCode: {isEdited: true,
                                              isValid: false,
                                              message: 'Postal code length must be 6' }}
                          })
                        }
                        else{
                          setFieldValidity({
                            ...fieldValidity,
                            ...{postalCode: {isEdited: true, isValid: true }}
                          })
                        }
                      }

                    }}
                    error = { 'postalCode'in fieldValidity && (!fieldValidity['postalCode'].isValid) }
                    // helperText="Postal code for your customized experience"
                    helperText = { ('postalCode'in fieldValidity
                                    &&  (!fieldValidity['postalCode'].isValid)
                                  )? fieldValidity['postalCode'].message: ''
                                    }
                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phoneNum"
                      label="Phone Number"
                      name="phoneNum"
                      // defaultValue=''
                      type='number'
                      placeholder='xxxxxxxx'
                      disabled={step.phase !== SignupPhase.Init}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>
                                        <BtnSendOTP disabled={step.phase !== SignupPhase.Init} />
                                      </InputAdornment>
                      }}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                    />
                </Grid>
                { (step.phase === SignupPhase.OTPSent || step.phase === SignupPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="verifyCode"
                      // value={verifyCode}
                      label="Verification Code"
                      name="verifyCode"
                      type='number'
                      disabled={false}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>

                                      </InputAdornment>

                      }}
                      // onChange={e => setVerifyCode(e.target.value)}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                    />
                  </Grid>
                }
                { (step.phase === SignupPhase.OTPSent || step.phase === SignupPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 0, mb: 2 }}

                    >
                      Verify
                    </Button>
                  </Grid>
                }
                { (step.phase === SignupPhase.OTPVerified )&&
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h5" align='center' sx={{ mt: 0, mb: 2 }}

                    >
                      Registerd !
                    </Typography>
                  </Grid>
                }
              </Grid>

            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
          <MySnackBar open={showSnackBar}
            setOpen={setShowSnackBar}
            info={snackBarInfo}
            anchorOrigin={{ vertical:'top', horizontal:'center' }}
            />
        </Container>
        <FabNavigateBack/>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}
