import {
  CommentsDisabledOutlined, CommentOutlined, }    from '@mui/icons-material';
import {Stack, Avatar, ListItemText, Box,
  ListItem, ListItemButton, ListItemAvatar,  }    from '@mui/material'

import { useEffect, useState }    from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import  InfiniteScroll          from 'react-infinite-scroll-component'

import dataProv                   from '../dataProvider'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu}                  from '../utils/fabMenu'
import {FabNavigateBack}          from '../utils/route'
import {StoreInfoFragment}        from './StoreInfoFragment'


type UserInfo = {
  postal_code?:  string,
  nick_name?:    string,
  phone_num?:    string,
  phone_num_verified?: boolean,
}

type ProductViewModel = {
  name:     string,
  desc:     string,
  thumbnail_src: string,
  url:      string,
  id:       string,
}

type Pagination = {
  currentPage:  number,
  itemTotal:    number,
  itemPerPage:  number,
}

export const SellerHome = () =>{

  const [refreshCount,  setRefreshCount]  = useState(0)
  const [viewModels,    setViewModels]    = useState<ProductViewModel[]>([])
  const [pagination,    setPagination]    = useState<Pagination>(
    {currentPage: 1, itemTotal: 1000, itemPerPage: 10,}
  )

  const [userInfo,    setUserInfo] = useState<UserInfo>();

  const [isLoading, setIsLoading] = useState(false)

  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo,  setSnackBarInfo]  = useState<SnackbarInfo>({
    message: "default msg", severity: 'success'
  })
  const navigate  = useNavigate();
  const location  = useLocation();
  const params    = useParams();

  const handleItemClicked=(model: ProductViewModel)=>{
    navigate(model.url, {state: {}})
  }

  const {storeId} = params;
  console.log(storeId)

  useEffect(()=>{

    if( !storeId ){
      return
    }
    new Promise(async()=>{

      setIsLoading(true)
      await fetchMoreData(storeId)
      setIsLoading(false)

    }).catch((e)=>{
      setIsLoading(false)
    })

  },[refreshCount, params])


  useEffect(()=>{

    new Promise(async()=>{

      setIsLoading(true)
      setIsLoading(false)

    }).catch((e)=>{
      setIsLoading(false)
    })

  },[refreshCount, params])

  const fetchModels = async (
    { page, perPage, storeId }: any
  ): Promise<[ProductViewModel[], number]> =>{

    // const hostId = params.pathname?.split('/')[-1]
    // console.log({host_id})

    const listResult = await dataProv.getList(
      'products', {
        pagination: {page:page, perPage:perPage},
        filter:     {'storeId': storeId},
        sort:       {field:'create_time', order:'DESC'}
      }
    );

    const viewModelList: ProductViewModel[] = []
    for(const prod of listResult.data){
      // const host = line.host
      console.log(prod)
      viewModelList.push({
        id: prod.id,
        name: prod.title,
        desc: prod.desc,
        thumbnail_src: prod.medias.length > 0 ? prod.medias[0].src : '/icons/apple-touch-icon.png',
        url:  `/product/${prod.id}`
      })
      // lineModelList.push({
      //   host: {
      //     avatar_src: line.host.src,
      //     group_name: line.host.groupName,
      //     group_desc: line.host.groupDesc,
      //   },
      //   line: {
      //     name:     line.title,
      //     desc:     line.desc.slice(0,35) + '...',
      //     url:      `/line/${line.id}`,
      //     is_open:  line.is_open,
      //     id:       line.id,
      //     thumbnail_src: line.medias[0].src
      //   },
      // })
    }
    return [viewModelList, listResult.total]
  }

  const refresh = ()=>{
    setRefreshCount(refreshCount+1)
  }

  const  fetchMoreData = async (storeId: string) => {

    const [moreItems, total] = await fetchModels({
        page:     pagination.currentPage,
        perPage:  pagination.itemPerPage,
        storeId:   storeId,
      })
    setViewModels([...viewModels, ...moreItems])
    setPagination({
      ...pagination,
      currentPage:  pagination.currentPage+1,
      itemTotal:    total,
    })

  }

  const hasMore = ()=>{
    // return pagination.itemPerPage * pagination.currentPage < pagination.itemTotal
    return viewModels.length < pagination.itemTotal
  }

  return (
    <main style={{  }}>
    <Stack justifyContent='center' alignItems='center'
          direction="column" spacing={1} paddingY='15px'
          >
      {
        storeId && <StoreInfoFragment storeId={storeId} />
      }
      <InfiniteScroll
          dataLength={viewModels.length}
          next={()=>{fetchMoreData(params.hostId??"...")}}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
        >
        {viewModels.map((viewModel) => {
          // const labelId = `checkbox-list-secondary-label-${slot.id}`;
          return (
            <ListItem
              key={viewModel.url}
              // secondaryAction={
              //   <Checkbox edge="end" // onChange={handleToggle(value)}
              //     checked={checked.indexOf(value) !== -1}
              //     inputProps={{ 'aria-labelledby': labelId }}
              //   />
              // }
              disablePadding
            >
              <ListItemButton onClick={()=>{handleItemClicked(viewModel)}}>
                <ListItemAvatar sx={{paddingRight: '10px'}}>
                  {
                    // <Avatar
                    //   // alt={`Avatar n°${value + 1}`}
                    //   alt={viewModel.name}
                    //   src={viewModel.thumbnail_src}
                    //   // src={`/static/images/avatar/${value + 1}.jpg`}
                    // />
                    <Box component="img"
                      sx={{
                        height: {xs: 120, md: 120},
                        minHeight: { xs: 120, md: 120 },
                        maxWidth:   { xs: 100, md: 120},
                        objectFit: 'contain',
                        borderRadius: '5px',
                        border: 2,
                        borderColor: '#DDDDDD',
                      }}
                      alt="The house from the offer."
                      src={viewModel.thumbnail_src}
                    />
                  }
                </ListItemAvatar>
                <ListItemText
                    id={`${viewModel.id}-title`}
                    primary={viewModel.name}
                    secondary={viewModel.desc}
                />
                {
                  // viewModel.line.is_open?
                  //   <CommentOutlined color='info'/>:
                  //   <CommentsDisabledOutlined color='disabled'/>
                }
              </ListItemButton>
            </ListItem>
          );
        })}

      </InfiniteScroll>

      </Stack>
      <FabMenu/>
      <FabNavigateBack/>
      <MySnackBar open={showSnackBar} setOpen={setShowSnackBar} info={snackBarInfo}/>
    </main>
  );
}
